import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image, Popconfirm, Button, Tag, Slider, Space, Tooltip, Modal, Collapse, message, Skeleton } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from '/src/views/Auth/Login/selector';
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import screenfull from 'screenfull';
import { FullscreenOutlined, PlaySquareOutlined } from "@ant-design/icons";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import loadable from '@loadable/component'
import { getSeconds, getMinutes, checkIPAD, checkMob, checkURLSource, checkURLExist, checkProcessingProgress } from "/src/lib/utils/helperMethods";
import {
  CaretRightOutlined,
  PauseOutlined
} from "@ant-design/icons";
import { showConfirmModal } from "../../Segment/UIHelper";
import { IoMdVolumeOff, IoMdVolumeHigh} from "react-icons/io";
import "./CustomVideoPlayer.scss"
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { printModeSelector } from "/src/views/Experiences/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { processForConversion } from "../MediaHelper";
import dayjs from "dayjs";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { getAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

const ReactPlayer = loadable(() => import(/* webpackChunkName: "react-player" */ 'react-player'))

const { Panel } = Collapse;

const CustomVideoPlayer = props => {
  console.log("Custom Video Player Props ==>", props)
  const { 
    file = {},
    contentType,
    mediaPlayer, 
    mediaStateSetting, 
    setMediaStateSetting, 
    mediaShowSetting, 
    setMediaShowSetting,
    activeMediaPlaying, 
    handlePlayPause, 
    handleOnPlay, 
    setActiveMediaPlaying, 
    reload,
    setReload,
  } = props

  const timeSnippets = file.snippets || [{}]
  const isIPAD = checkIPAD();
  const isMobile = checkMob();
  const appType = useSelector(appTypeSelector())
  const currentUser = useSelector(currentUserSelector());
  // const enabledFeatures = useSelector(enabledFeaturesSelector())
  // const experienceViewMode = useSelector(experienceViewModeSelector())
  const printMode = useSelector(printModeSelector())
  const embedded = useSelector(getEmbeddedSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const currentAppVersion = getAppVersion(appType)
  const progressIntervalRef = useRef(null);
  const mrIntl = useTranslate()

  const [processingProgress, setProcessingProgress] = useState();
  const [processedForSEB, setProcessedForSEB] = useState(file.convert_status === "completed");

  const expectedProcessingTime = 30
  const isExpectedProcessingTimeCompleted = 
    dayjs(file.updated_at).isBefore(dayjs().subtract(expectedProcessingTime, "m"))

  let usingSnippets = file.using_snippets
  let showtranscriptPanel = file.include_transcript && !mediaShowSetting.createMode && !printMode && !embedded
  
  // Turning off using snippets case for SEB Youtube urls which does not have webm format
  if ((import.meta.env.VITE_MODE === "production" || 
    import.meta.env.VITE_MODE === "china_production") && 
    appType === "seb" && 
    file.webm_url) {
    usingSnippets = false
  }

  const urlSource = checkURLSource(file.url)
  let toSubtractPerSnippetRange = []
  let displayTimeRange = []
  // let currentSnippetIdx = 0

  let durationTemp = 0
  let durationRangeTemp = 0

  // TODO: add bottom map to useEffect onload
  timeSnippets.forEach((snippet, i) => {
    let sub = 0
    if (i == 0) {
      sub = snippet.start
    } else {
      durationTemp += (timeSnippets[i - 1].end - timeSnippets[i - 1].start)
      sub = snippet.start - durationTemp
    }
    toSubtractPerSnippetRange.push(sub)

    durationRangeTemp += (snippet.end - snippet.start)
    displayTimeRange.push(durationRangeTemp)
  })

  let defaultVideoPlayerSettings = {
    playing: false,
    volume: 0.6,
    muted: (isIPAD || appType === "ios" || isMobile || appType === "mac") ? true : false,
    currentTime: 0,
    displayCurrentTime: 0,
    startTime: 0,
    duration: 0,
    firstPlay: true,
    showCustomControls: false, // TODO: should be true by default?
    currentSnippetIdx: 0,
    hasEnded: true,
    showMuteButton: (isIPAD || appType === "ios" || isMobile || appType === "mac") ? true : false,
    showVolumeControl: (!isIPAD && appType !== "ios" && !isMobile) ? true : false,
    useCustomFullScreen:  isIPAD || appType === "ios" || appType === "mac" ? true : false
  }

  if (usingSnippets) {
    defaultVideoPlayerSettings.currentTime = timeSnippets[0].start
    defaultVideoPlayerSettings.startTime = timeSnippets[0].start
  }
  const [videoPlayerSettings, setVideoPlayerSettings] = useState(defaultVideoPlayerSettings)
  const [playedCount, setPlayedCount] = useState(0);

  // const mediaPlayer = useRef(null)
  

  console.log("videoPlayerSettings ==>", videoPlayerSettings)

  const getDuration = (duration) => {
    console.log("line number", duration)
    let newDuration = 0
    if (usingSnippets) {
      timeSnippets.map((snippet, i) => {
        newDuration += (snippet.end - snippet.start)
      })
    } else {
      newDuration = duration
    }
    return newDuration
  }

  const getCurrentSnippet = () => {

  }

  const getDisplayAndCurrentTime = (playedSeconds = null, seekValue = null) => {
    // also add functionality for seek, currentSnippet should be in state?
    let displayCT
    let cT
    let updatedCurrentSnippetIdx = videoPlayerSettings.currentSnippetIdx

    console.log("onProgress in fn - videoPlayerSettings", videoPlayerSettings, playedSeconds, seekValue)

    if (usingSnippets) {
      // if (playedSeconds) { // can be 0 - was a bug when starting at 0 showing NaN
      if (playedSeconds != null) {
        // normal playing
        cT = playedSeconds
        let currentSnippet = timeSnippets[updatedCurrentSnippetIdx]
        console.log("onProgress in fn checking", videoPlayerSettings.currentTime, currentSnippet)
        if (videoPlayerSettings.currentTime >= currentSnippet.end) {
          updatedCurrentSnippetIdx += 1
          console.log("onProgress update index - go to next snippet", updatedCurrentSnippetIdx, toSubtractPerSnippetRange)
          currentSnippet = timeSnippets[updatedCurrentSnippetIdx]

          console.log("currentSnippet", currentSnippet)
          if (currentSnippet == undefined) {
            console.log("hasEnded true")
            return { "hasEnded": true }
          }
          if (updatedCurrentSnippetIdx > 0 && (currentSnippet.start - timeSnippets[updatedCurrentSnippetIdx - 1].end) == 1) {
            cT = currentSnippet.start + 1
            // case when end of prev snippet and start of next snippet is 1 sec apart - to avoid repeat play of that 1 sec, adding 1s
          } else {
            cT = currentSnippet.start
          }
          displayCT = currentSnippet.start - toSubtractPerSnippetRange[updatedCurrentSnippetIdx]

          mediaPlayer.current.seekTo(cT)
        } else {
          console.log("onProgress in snippet", updatedCurrentSnippetIdx, cT, toSubtractPerSnippetRange[updatedCurrentSnippetIdx])
          // even after hasEnded its going into onProgress and hence coming here so adding a check here
          if (videoPlayerSettings.hasEnded) {
            console.log("hasEnded true")
            return { "hasEnded": true }
          }
          displayCT = cT - toSubtractPerSnippetRange[updatedCurrentSnippetIdx]
        }
      }
      // if (seekValue) { // can be 0 - was a bug when seeking to 0
      if (seekValue != null) {
        // when seeking
        displayCT = seekValue
        // let currentSnippetIdx = getCurrentSnippetIdx() - for seek

        let toSubFromEnd
        for (let i = 0; i < displayTimeRange.length; i++) {
          console.log("seekValue displayTimeMarker", displayCT, displayTimeRange[i], displayTimeRange, i)
          if (displayCT <= displayTimeRange[i]) {
            console.log("seekValue found range", i)
            updatedCurrentSnippetIdx = i
            toSubFromEnd = displayTimeRange[i] - displayCT
            break;
          }
        }

        cT = timeSnippets[updatedCurrentSnippetIdx].end - toSubFromEnd

        console.log("seekValue", displayCT, cT, updatedCurrentSnippetIdx, displayTimeRange, toSubFromEnd)
      }


    } else {
      // displayCT = playedSeconds
      if (playedSeconds != null) {
        displayCT = cT = playedSeconds
      }
      if (seekValue != null) {
        displayCT = cT = seekValue
      }
    }

    console.log("getDisplayAndCurrentTime return", parseInt(displayCT), parseInt(cT), updatedCurrentSnippetIdx)
    return { "displayCT": parseInt(displayCT), "cT": parseInt(cT), "currentSnippetIdx": updatedCurrentSnippetIdx }
  }

  const customVideoPlayerDiv = useRef(null)
  const [fullscreenActive, setFullScreenActive] = useState(false);

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled && !videoPlayerSettings.useCustomFullScreen) {
      screenfull.toggle(customVideoPlayerDiv.current);
    }
    setFullScreenActive(!fullscreenActive);

  };

  // getting multiple instance of progress interval due to remounting of customVideoPlayer on error(setting reload on error  which is used in its keys).
  // so clearing interval on relaod and unmounting
   const clearProgressIntervalRef = () => {
    if(progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current)
      progressIntervalRef.current = null;
    }
   }

  useEffect(() => {
    if(screenfull.isEnabled) {
      screenfull.on('change', () => {
        if (!screenfull.isFullscreen) {
          setFullScreenActive(false);
        }
      });
      return screenfull.off('change', (event) => {
        console.log("remove change event", event);
      });
    }
  }, [])

  useEffect(() => {
    console.log("activeMediaPlaying useEffect should be called ==>", videoPlayerSettings)
    setVideoPlayerSettings({
      ...videoPlayerSettings,
      playing: activeMediaPlaying,
    });
    if (activeMediaPlaying === true) {
      setPlayedCount(playedCount + 1);
    }
  }, [activeMediaPlaying])

  useEffect(() => {
    const checkProgressInterval = 15000
    if (!embedded &&
      (urlSource === "vimeo" || 
      urlSource === "s3" || 
      urlSource === "yt") && 
      file.convert_status === "processing") {
        const urlExistOptions = {
          url: file.webm_url,
          interval: checkProgressInterval,
          successCallback: (value) => {
            setProcessedForSEB(true);
            if (mediaShowSetting.createMode) {
              mediaShowSetting.onChange({
                ...file,
                convert_status: "completed",
              })
            } else {
              if (mediaShowSetting.segment) {
                segmentActions.showSuccess({
                  data: {
                    segment: {
                      ...mediaShowSetting.segment,
                      resource_segment_attributes: {
                        ...mediaShowSetting.segment.resource_segment_attributes, //We allow to upload one video so spreading, When multiple video allowed then need to revisit this.
                        attachments_json: {
                          attachments: [{
                            ...file,
                            convert_status: "completed",
                          }]
                        }
                      },
                    }
                  }
                })
              }
            }
          },
        }
        checkProcessingProgress({
          job_id: file.job_id,
          interval: checkProgressInterval,
          intervalRef: progressIntervalRef,
          successCallback: (value, checkProgressInterval) => {
            setProcessingProgress(value.progress)
            if (value.progress === 100) {
              checkURLExist(urlExistOptions, checkProgressInterval)
            }
          },
          errorCallback: (error, checkProgressInterval) => { 
            checkURLExist(urlExistOptions, checkProgressInterval)
          }
        });
    }
    return () => {
      clearProgressIntervalRef();
    }
  }, [contentType, file.webm_url]);

  const toggleMute = (value) => {
    setVideoPlayerSettings({
      ...videoPlayerSettings,
      muted: value,
    })
  }
  const remainingPlaybackChance = file.playback_limit - playedCount
  const playPauseTitle = videoPlayerSettings.playing ? mrIntl("CommonText.pause") : mrIntl("CustomVideoPlayer.play");

  const reprocessVideo = () => {
    // let updatedAtc = {
    //   ...file,
    //   updated_at: Date.now()
    // };

    // Note updated_at will be always first time when file is uploaded
    // Process only when segment_id
    processForConversion(
      {
        item_type: "Segment",
        item_id: mediaShowSetting.segment_id,
        atc: file,
      },
      {
        successCallback: () => {
          console.log("processForConversion callback ==>",);
          if (mediaShowSetting.createMode) {
            mediaShowSetting.onChange({
              ...file,
              convert_status: "processing",
            })
          } else {
            if (mediaShowSetting.segment) {
              segmentActions.showSuccess({
                data: {
                  segment: {
                    ...mediaShowSetting.segment,
                    resource_segment_attributes: {
                      ...mediaShowSetting.segment.resource_segment_attributes,
                      attachments_json: {
                        attachments: [{
                          ...file,
                          convert_status: "processing",
                        }]
                      }
                    },
                  }
                }
              })
            }
          }
          // mediaShowSetting.onChange(file)
          message.warning(mrIntl("VideoForm.video_processing_started_msg"));
        },
        errorCallback: () => {
          message.error(mrIntl("CommonText.something_went_wrong_please_try_again"));
          // setMediaStateSetting({
          //   ...mediaStateSetting,
          //   processing: false,
          // });
        },
      }
    );
  };

  const reprocessButton = 
    <Button
      danger
      onClick={() => reprocessVideo()}
    >
      <MrTranslate id={"CustomVideoPlayer.reprocess_button"}/>
    </Button>

  const setIframeTabIndex = () => {
    if (mediaPlayer.current) {
      const internalPlayer = mediaPlayer.current.getInternalPlayer();
      if (internalPlayer?.getIframe) {
        const iframe = internalPlayer.getIframe();
        if (iframe && !iframe.hasAttribute("tabIndex")) {
          iframe.setAttribute("tabIndex", "-1"); // Make iframe unfocusable
        }
      }
    }
  }
  
  let reactPlayerProps = {
    width: "100%",
    light: false, //NOTE: Some OS 12.2-3/M1 macs, the video wasn't playing with just play, needed volume/seek action before playing. Light is actually better and should make this default after handling other cases.
    url: `${
      appType === "seb" &&
      file.webm_url && currentAppVersion !== "3.8.0.999"
        ? file.webm_url 
        : file.url  // APL-3215 - attachments was mutating after edit changing youtube url. Both youtube and old webm_url coexists
    }`,
    controls: true,
    onPlay: () => {
        // handleOnPlay(); // no need, alredy calling handlePlayPause on play/pause button
        console.log("customVideoPlayer event onPlay");
    },
    playing: activeMediaPlaying,
    onDuration: (duration) => {
      console.log("Player duration is ==>", duration);

      setVideoPlayerSettings((videoPlayerSettings) => ({
        ...videoPlayerSettings,
        duration: getDuration(duration),
      }));
      
      if (setMediaShowSetting) {
        setMediaShowSetting({
          ...mediaShowSetting,
          duration: duration,
        });
      }
    },
    onReady: () => {
      console.log("react player event - onReady", mediaStateSetting);
      setMediaStateSetting({
        ...mediaStateSetting,
        ready: true,
        error: false
      });
      setIframeTabIndex()
    },
    onEnded: (e) => {
      setActiveMediaPlaying(false)
    },
    onError: (error) => {
      console.log("Some error occured", error);
      let errorMessage = ""
      if (mediaShowSetting.showProcessingError && error.message && error.message.indexOf("vimeo") >= 0 && error.message.indexOf("was not found") >= 0) { // Vimeo video was not found means, vimeo video is not processed yet
        errorMessage = <>Processing video for <strong>Windows SEB.</strong> (Estimated time: {expectedProcessingTime} mins).</>
      } else {
        errorMessage = mrIntl("CommonText.unable_to_load_the_video")
      }
      setMediaStateSetting({
        ...mediaStateSetting,
        showError: true,
        errorMessage: errorMessage
      });

      setTimeout(() => {
        setReload((reload) => reload + 1);
        clearProgressIntervalRef();
      }, 30000)
    },
    config: {
      vimeo: {
        width: "100%"
      }
    }
  }

  if (mediaShowSetting.custom_player) {
    reactPlayerProps = {
      ...reactPlayerProps,
      height:
        fullscreenActive 
        ? file.playback_limit_enabled 
          ? "97%" 
          : "100%"
        : "360px",
      controls: false,
      muted: videoPlayerSettings.muted,
      volume: videoPlayerSettings.volume,
      playing: videoPlayerSettings.playing,
      // light: `https://marketingland.com/wp-content/ml-loads/2014/09/video-play-button-ss-1920-800x450.jpg`,
      // onClickPreview: () => { console.log("customVideoPlayer event onClickPreview") },
      onStart: () => {
        console.log("customVideoPlayer event onStart");
        if (usingSnippets && videoPlayerSettings.firstPlay) { // only on first play
          mediaPlayer.current.seekTo(videoPlayerSettings.startTime);
        }

        if (videoPlayerSettings.firstPlay){
          setVideoPlayerSettings({
            ...videoPlayerSettings,
            firstPlay: false,
          });
        }
      },
      onReady: () => {
        console.log("customVideoPlayer event - onReady", videoPlayerSettings);

        setMediaStateSetting({
          ...mediaStateSetting,
          ready: true,
          error: false,
        });

        setVideoPlayerSettings((videoPlayerSettings) => {
          let newVideoPlayerSettings = {
            ...videoPlayerSettings,
            showCustomControls: true,
            hasEnded: false,
            // playing: usingSnippets ? true : false,
          }
          return newVideoPlayerSettings
        });
        setIframeTabIndex()
      },
      onProgress: (e) => {
        console.log(
          "customVideoPlayer event onProgress start",
          e,
          videoPlayerSettings,
          playedCount
        );
        let playedSeconds = e.playedSeconds
        if (videoPlayerSettings.firstPlay) {
          playedSeconds = videoPlayerSettings.startTime
        }
        // onProgress gets called once onReady as well
        // we were seeking onReady and that was setting e.playedSeconds. but we don't want to seek onReady. - only on first play now - so to get the right display time, we use start time here
        
        let displayAndCT = getDisplayAndCurrentTime(playedSeconds);
        console.log("customVideoPlayer event onProgress before set state", displayAndCT, playedSeconds);
        if (displayAndCT.hasEnded) {
          // IMP: only for snippets - otherwise always onEnded
          // setVideoPlayerSettings({
          //   ...defaultVideoPlayerSettings,
          //   playing: false,
          //   currentTime: 0,
          //   displayCurrentTime: 0,
          //   currentSnippetIdx: 0
          // })
          setVideoPlayerSettings({ 
            ...defaultVideoPlayerSettings, 
            hasEnded: false, 
            showCustomControls: true,
            firstPlay: false, // play after ending does not trigger onStart again
            duration: videoPlayerSettings.duration // preserve duration
          });
          mediaPlayer.current.seekTo(defaultVideoPlayerSettings.startTime);
          handlePlayPause()

          // mediaPlayer.current.showPreview(); // TODO: should use this? light mode issues in some macs no?
        } else {
          setVideoPlayerSettings({
            ...videoPlayerSettings,
            currentTime: displayAndCT.cT,
            displayCurrentTime: displayAndCT.displayCT,
            currentSnippetIdx: displayAndCT.currentSnippetIdx,
          });
        }
      },
      onEnded: () => {
        if(usingSnippets){
          // calling same actions as our custom hasEnded - case when end time with snippets is same as actual video end - this gets called too/before ours
          setVideoPlayerSettings({ 
            ...defaultVideoPlayerSettings, 
            hasEnded: false, 
            showCustomControls: true,
            firstPlay: false, // play after ending does not trigger onStart again
            duration: videoPlayerSettings.duration // preserve duration
          });
          mediaPlayer.current.seekTo(defaultVideoPlayerSettings.startTime);
          handlePlayPause()

        } else {
          // TODO: Check limit playback with snippets and then clean up onEnded and hasEnded case below
          setTimeout(() => {
            setVideoPlayerSettings({
              ...videoPlayerSettings,
              playing: false,
              displayCurrentTime: videoPlayerSettings.duration
            });
          }, 1100);
          setActiveMediaPlaying(false)
          if (file.playback_limit_enabled && currentUser.role == "student") {
            if (screenfull.isEnabled) {
              screenfull.exit();
            }
            showConfirmModal({
              // title: `Are you sure to remove this ${props.tabPrefix}?`,
              title:
                playedCount < file.playback_limit
                  ? mrIntl("CustomVideoPlayer.want_to_watch_again_msg")
                  : mrIntl("CustomVideoPlayer.you_exhausted_play_chances"),
              okButtonProps: playedCount < file.playback_limit || {
                style: { display: "none" },
              },
              confirm: ({ className: 'show-Confirm-Modal' }),
              onOk: () => {
                setVideoPlayerSettings({
                  ...videoPlayerSettings,
                  playing: true,
                });
                setPlayedCount(playedCount + 1);
                return;
              },
              cancelText: remainingPlaybackChance == 0 ? mrIntl("CommonText.ok") : mrIntl("CommonText.no"), // showing OK instead of OK in "exhausted play chances" modal
              mrIntl: mrIntl
            })
          }
        }
      },
    }
  }
  
  return (
    <div 
      key={`custom-video-player-${file.id}`}
      ref={customVideoPlayerDiv} 
      className={videoPlayerSettings.useCustomFullScreen && fullscreenActive && "custom-video-full-screen"}
      >
      {/* <Button onClick={() => handlePlayPause()}>Play</Button> */}
      {/* videoPlayerSettings -> {JSON.stringify(videoPlayerSettings)}
      <br/>
      activeMediaPlaying -> {JSON.stringify(activeMediaPlaying)} */}
      {
        <span>
          {currentUser.role === "student" && 
          file.playback_limit_enabled && (
            <div>
              {/* <p>
                {file.playback_limit == playedCount
                  ? "You exhausted your play video chances" : "You can watch this video " +
                  `${file.playback_limit - playedCount}` + " times"}
              </p> */}
              <p>
                {file.playback_limit === playedCount
                  ? mrIntl("CustomVideoPlayer.exhausted_your_play") : mrIntl("CustomVideoPlayer.video_playback_limit_msg", {playedCount: remainingPlaybackChance})}
              </p>
            </div>
          )}
          {videoPlayerSettings.showCustomControls && (
            <div
              className={"custom-player"}
              style={{ height: fullscreenActive ? "100%" : "360px" }}
            >
              <div
                className={"custom-overlay"}
                style={{ height: fullscreenActive ? "90%" : "360px" }}
                onClick={(event) => {
                  if (file.playback_limit_enabled) {
                    event.preventDefault();
                  } else {
                    toggleMute(false)
                    handlePlayPause()
                  }
                }}
              // setVideoPlayerSettings({
              //   ...videoPlayerSettings,
              //   playing: !videoPlayerSettings.playing,
              // });
              >
              </div>
              <div className={"custom-controls"}>
                <Space direction="vertical" className={"seek-space"}>
                  <Slider
                    className={"seek-slider"}
                    value={videoPlayerSettings.displayCurrentTime}
                    max={videoPlayerSettings.duration}
                    // onChange={(value) => {
                    //   setVideoPlayerSettings({
                    //     ...videoPlayerSettings,
                    //     playing: false,
                    //   })
                    // }}
                    // onAfterChange={(value) => {
                    onChange={(value) => {
                      console.log(
                        "seek-slider value",
                        value,
                        videoPlayerSettings
                      );
                      let displayAndCT = getDisplayAndCurrentTime(null, value);
                      // let displayCT = value
                      // let cT
                      // if(usingSnippets){
                      //   cT = getDisplayAndCurrentTime()
                      // }else{
                      //   cT = value
                      // }
                      setVideoPlayerSettings({
                        ...videoPlayerSettings,
                        currentTime: displayAndCT.cT,
                        currentSnippetIdx: displayAndCT.currentSnippetIdx,
                        // playing: usingSnippets ? false : true // doing this to pause video on seek - was causing issues with snippets,
                      });

                      mediaPlayer.current.seekTo(displayAndCT.cT);

                      // setTimeout(() => {
                      //   setVideoPlayerSettings({
                      //     ...videoPlayerSettings,
                      //     playing: true,
                      //   })
                      // }, 500);
                    }}
                    tipFormatter={(value) => {
                      return getMinutes(value) + ":" + getSeconds(value);
                    }}
                    style={
                      file.playback_limit_enabled
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  />
                  <Space>
                    <Tooltip
                      placement={"bottom"}
                      title={playPauseTitle}
                    >
                      <Button
                        type="text"
                        icon={
                          videoPlayerSettings.playing ? (
                            <PauseOutlined />
                          ) : (
                            <CaretRightOutlined />
                          )
                        }
                        onClick={() => {
                          toggleMute(false)
                          handlePlayPause()
                        }}
                        style={
                          currentUser.role === "student" &&
                            file.playback_limit_enabled &&
                            (videoPlayerSettings.playing ||
                            remainingPlaybackChance === 0)
                            ? { display: "none" }
                            : {}
                        }
                      ></Button>
                    </Tooltip>
                    {/* TODO: Mute button UI */}
                    {videoPlayerSettings.showMuteButton && <Tooltip 
                      placement={"bottom"}
                      title={videoPlayerSettings.muted ? mrIntl("CustomVideoPlayer.unmute") : mrIntl("CustomVideoPlayer.mute")}
                    >
                      <Button
                        type="text"
                        icon={videoPlayerSettings.muted ? <IoMdVolumeOff /> : <IoMdVolumeHigh/>}
                        onClick={() => toggleMute(!videoPlayerSettings.muted)}
                        style={{paddingTop: "5px"}}
                      ></Button>
                    </Tooltip>}
                    {videoPlayerSettings.showVolumeControl && <Slider
                      className={"volume-slider"}
                      defaultValue={defaultVideoPlayerSettings.volume*10}
                      max={10}
                      tooltipPlacement={"bottom"}
                      onChange={(value) => {
                        console.log("slider value", value);
                        setVideoPlayerSettings({
                          ...videoPlayerSettings,
                          volume: value / 10,
                        });
                      }}
                    />}
                    <span>
                      {getMinutes(videoPlayerSettings.displayCurrentTime)}:
                      {getSeconds(videoPlayerSettings.displayCurrentTime)}/
                      {getMinutes(videoPlayerSettings.duration)}:
                      {getSeconds(videoPlayerSettings.duration)}
                    </span>
                    {!mediaShowSetting.hideFullScreenButton && (
                      <Button
                        className="full-screen-button"
                        onClick={() => handleClickFullscreen()}
                        icon={
                          fullscreenActive ? (
                            <BsFullscreenExit />
                          ) : (
                            <BsFullscreen />
                          )
                        }
                        type="text"
                      />
                    )}

                    {/* <span>{getMinutes(videoPlayerSettings.currentTime)}:{getSeconds(videoPlayerSettings.currentTime)}</span> */}
                  </Space>
                </Space>
              </div>
            </div>
          )}

          {!mediaStateSetting.ready &&
            <Skeleton.Node active={!mediaStateSetting.error} className="video-player-placeholder">
              <PlaySquareOutlined/>
            </Skeleton.Node>
          }
          <ReactPlayer
            key={`react-player-${file.id}-${reload}`}
            ref={mediaPlayer}
            {...reactPlayerProps}
            // width={"100%"}
            // height={
            //   fullscreenActive 
            //   ? file.playback_limit_enabled 
            //     ? "97%" 
            //     : "100%"
            //   : "360px"
            // }
            // url={`${
            //   appType === "seb" && 
            //   file.webm_url
            //     ? file.webm_url 
            //     : file.url
            //   }`} // APL-3215 - attachments was mutating after edit changing youtube url. Both youtube and old webm_url coexists
            // light={false} // temp fix to re-render player - in some OS 12.2-3/M1 macs, the video wasn't playing with just play, needed volume/seek action before playing. Light is actually better and should make this default after handling other cases.
            // controls={false}
            // muted={videoPlayerSettings.muted}
            // onPlay={() => {
            //   handleOnPlay();
            //   console.log("customVideoPlayer event onPlay");
            // }}
            // onStart={() => {
            //   console.log("customVideoPlayer event onStart");
            //   if (usingSnippets && videoPlayerSettings.firstPlay) { // only on first play
            //     mediaPlayer.current.seekTo(videoPlayerSettings.startTime);
            //   }

            //   if (videoPlayerSettings.firstPlay){
            //     setVideoPlayerSettings({
            //       ...videoPlayerSettings,
            //       firstPlay: false,
            //     });
            //   }
            // }}
            // // playsinline={true}
            // volume={videoPlayerSettings.volume}
            // playing={videoPlayerSettings.playing}
            // // light={`https://marketingland.com/wp-content/ml-loads/2014/09/video-play-button-ss-1920-800x450.jpg`}
            // // onClickPreview={() => { console.log("customVideoPlayer event onClickPreview") }}
            // onDuration={(duration) => {
            //   console.log("customVideoPlayer event - onDuration ==>", duration, getDuration(duration));
            //   setVideoPlayerSettings((videoPlayerSettings) => ({
            //     ...videoPlayerSettings,
            //     duration: getDuration(duration),
            //   }));

            //   if (setMediaShowSetting) {
            //     setMediaShowSetting({
            //       ...mediaShowSetting,
            //       duration: duration,
            //     });
            //   }
            //   // console.log("customVideoPlayer event - after setting duration ==>", videoPlayerSettings);
            // }}
            // onReady={() => {
            //   // const duration = getDuration()
            //   console.log(
            //     "customVideoPlayer event - onReady",
            //     videoPlayerSettings
            //   );

            //   // moved to onStart
            //   // if (usingSnippets) {
            //   //   mediaPlayer.current.seekTo(videoPlayerSettings.startTime);
            //   // }

            //   setMediaStateSetting({
            //     ...mediaStateSetting,
            //     ready: true,
            //     error: false,
            //   });

            //   setVideoPlayerSettings((videoPlayerSettings) => {
            //     let newVideoPlayerSettings = {
            //       ...videoPlayerSettings,
            //       showCustomControls: true,
            //       hasEnded: false,
            //       // playing: usingSnippets ? true : false,
            //     }
            //     return newVideoPlayerSettings
            //   }); 

            //   // handlePlayPause()

            //   // if (setMediaShowSetting) {
            //   //   setMediaShowSetting({
            //   //     ...mediaShowSetting,
            //   //     duration: mediaPlayer.current.getDuration()
            //   //   })
            //   // }
            // }}
            // onError={(error) => {
            //   console.log("Some error occured", error);
            //   let errorMessage = ""
            //   if (error.message && error.message.indexOf("vimeo") >= 0 && error.message.indexOf("was not found") >= 0) {
            //     errorMessage = mrIntl("CommonText.video_may_take_upto_30_minutes")
            //   } else {
            //     errorMessage = mrIntl("CommonText.unable_to_load_the_video")
            //   }
            //   setMediaStateSetting({
            //     ...mediaStateSetting,
            //     showError: true,
            //     errorMessage: errorMessage
            //   });

            //   setTimeout(() => {
            //     setReload((reload) => reload + 1);
            //   }, 30000)
            // }}
            // onEnded={() => {
            //   if(usingSnippets){
            //     // calling same actions as our custom hasEnded - case when end time with snippets is same as actual video end - this gets called too/before ours
            //     setVideoPlayerSettings({ 
            //       ...defaultVideoPlayerSettings, 
            //       hasEnded: false, 
            //       showCustomControls: true,
            //       firstPlay: false, // play after ending does not trigger onStart again
            //       duration: videoPlayerSettings.duration // preserve duration
            //     });
            //     mediaPlayer.current.seekTo(defaultVideoPlayerSettings.startTime);
            //     handlePlayPause()

            //   } else {
            //     // TODO: Check limit playback with snippets and then clean up onEnded and hasEnded case below
            //     setTimeout(() => {
            //       setVideoPlayerSettings({
            //         ...videoPlayerSettings,
            //         playing: false,
            //         displayCurrentTime: videoPlayerSettings.duration
            //       });
            //     }, 1100);
            //     setActiveMediaPlaying(false)
            //     if (file.playback_limit_enabled && currentUser.role == "student") {
            //       if (screenfull.isEnabled) {
            //         screenfull.exit();
            //       }
            //       showConfirmModal({
            //         // title: `Are you sure to remove this ${props.tabPrefix}?`,
            //         title:
            //           playedCount < file.playback_limit
            //             ? mrIntl("CustomVideoPlayer.want_to_watch_again_msg")
            //             : mrIntl("CustomVideoPlayer.you_exhausted_play_chances"),
            //         okButtonProps: playedCount < file.playback_limit || {
            //           style: { display: "none" },
            //         },
            //         confirm: ({ className: 'show-Confirm-Modal' }),
            //         onOk: () => {
            //           setVideoPlayerSettings({
            //             ...videoPlayerSettings,
            //             playing: true,
            //           });
            //           setPlayedCount(playedCount + 1);
            //           return;
            //         },
            //         mrIntl: mrIntl
            //       })
            //     }
            //   }
            // }}
            // onProgress={(e) => {
            //   console.log(
            //     "customVideoPlayer event onProgress start",
            //     e,
            //     videoPlayerSettings,
            //     playedCount
            //   );
            //   let playedSeconds = e.playedSeconds
            //   if (videoPlayerSettings.firstPlay) {
            //     playedSeconds = videoPlayerSettings.startTime
            //   }
            //   // onProgress gets called once onReady as well
            //   // we were seeking onReady and that was setting e.playedSeconds. but we don't want to seek onReady. - only on first play now - so to get the right display time, we use start time here

            //   let displayAndCT = getDisplayAndCurrentTime(playedSeconds);
            //   console.log("customVideoPlayer event onProgress before set state", displayAndCT, playedSeconds);
            //   if (displayAndCT.hasEnded) {
            //     // IMP: only for snippets - otherwise always onEnded
            //     // setVideoPlayerSettings({
            //     //   ...defaultVideoPlayerSettings,
            //     //   playing: false,
            //     //   currentTime: 0,
            //     //   displayCurrentTime: 0,
            //     //   currentSnippetIdx: 0
            //     // })
            //     setVideoPlayerSettings({ 
            //       ...defaultVideoPlayerSettings, 
            //       hasEnded: false, 
            //       showCustomControls: true,
            //       firstPlay: false, // play after ending does not trigger onStart again
            //       duration: videoPlayerSettings.duration // preserve duration
            //     });
            //     mediaPlayer.current.seekTo(defaultVideoPlayerSettings.startTime);
            //     handlePlayPause()

            //     // mediaPlayer.current.showPreview(); // TODO: should use this? light mode issues in some macs no?
            //   } else {
            //     setVideoPlayerSettings({
            //       ...videoPlayerSettings,
            //       currentTime: displayAndCT.cT,
            //       displayCurrentTime: displayAndCT.displayCT,
            //       currentSnippetIdx: displayAndCT.currentSnippetIdx,
            //     });
            //   }
            // }}
          />
          {!embedded && 
          mediaShowSetting.showProcessingError && 
          appType !== "mac" && 
          appType !== "ios" &&
          (urlSource === "vimeo" || 
          urlSource === "s3" ||
          urlSource === "yt") && 
          !processedForSEB && (
            <h3 style={{ color: "orangered" }}>
              {mediaStateSetting.showError
              ? <>{mediaStateSetting.errorMessage}</>
              : file.convert_status === "processing"  
                ? isExpectedProcessingTimeCompleted &&
                  mediaShowSetting.createMode 
                  ? <>Still processing. click {reprocessButton}{" "}to retry.</>
                  : <>Processing video for <strong>Windows SEB.</strong> (Estimated time: {expectedProcessingTime} mins).
                  </>
                : file.convert_status === "failed" && (
                  <>Processing failed. Click {reprocessButton}{" "}to retry.</>
                )
              } 
              {" "}
              {mediaShowSetting.createMode &&
                <>Feel free to save and continue with other tasks.</>
              }
                {processingProgress === 0 && (
                  <>
                    <br />
                      Preparing...
                    <br />
                  </>
                )}
                {processingProgress > 0 && (
                  <>
                    <br />
                      {processingProgress}%
                    <br />
                  </>
                )}
            </h3>
          )}

        
          {showtranscriptPanel && <Collapse className="transcript-collapse">
            <Panel header="Show transcript" key="1">
              <RenderHtml text={file.transcript} />
            </Panel>
          </Collapse>
          }
        </span>
       
      }
    </div>
  );


  // return (
  //   <div key={`custom-video-player-${file.id}`} ref={customVideoPlayerDiv}>
  //     {isSafariOnlineSecure && isVideoModalVisible  && <Modal
  //       footer={null}
  //       closable={false}
  //       keyboard={false}
  //       className="video-modal-safari"
  //       title={null}
  //       visible={isVideoModalVisible}
  //       onOk={handleOk}
  //       onCancel={handleCancel}
  //     >
  //       {finalRender}
  //     </Modal>}
  //     {!isVideoModalVisible && finalRender}
  //   </div>
  // );
}
export default CustomVideoPlayer;